<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="sitesAndApps"
      hide-default-footer
      disable-pagination
      class="sites-table"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.details="{ item }">
        <span class="float-left">{{ item.details.name }}</span>
        <span class="float-right">{{ item.details.link }}</span>
      </template>
    </v-data-table>
    <v-card-actions class="pt-5">
      <v-spacer></v-spacer>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {
    sitesAndApps: {
      type: Array,
      default: null,
    },
    headers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      display: 10,
      items: [5, 10, 15, 20],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [1, 5, 10, 25, 50],
        total: 1,
        to: 1,
        from: 1,
        firstPage: 1,
        lastPage: 1,
        nextPage: 0,
        previousPage: 0,
      },
    };
  },
};
</script>

<style>
.sites-table thead {
  display: none !important;
}
</style>
