<template>
  <div>
    <highcharts :options="options"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import VariablePie from "highcharts/modules/variable-pie";

HighchartsMore(Highcharts);
VariablePie(Highcharts);

export default {
  components: {
    highcharts: Chart,
  },
  props: ["options"],
};
</script>
