<template>
  <general-card>
    <h5 class="px-5 py-2 header-bg">Filter</h5>
    <v-row class="pa-5">
      <v-col cols="2">
        <h5 class="mb-2 text-uppercase heading-color">Country</h5>
        <v-select
          :items="items"
          outlined
          dense
          append-icon="mdi-chevron-down"
          label="Select Country"
        ></v-select>
      </v-col>
      <v-col cols="1" class="d-flex justify-center pt-5 mx-10">
        <v-divider vertical></v-divider>
      </v-col>
      <v-col cols="2">
        <h5 class="mb-2 text-uppercase heading-color">Month</h5>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              v-model="selectedDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </general-card>
</template>

<script>
import GeneralCard from "../Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    items: ["Kenya", "Tanzania", "Uganda"],
    date: null,
    menu: false,
    selectedDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),
  components: { GeneralCard },
};
</script>
