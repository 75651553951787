<template>
  <v-row class="mt-5">
    <v-col cols="7">
      <variable-radius-pie :options="api.reachStats" />
    </v-col>
    <v-col cols="5" class="d-flex flex-column justify-center">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
import VariableRadiusPie from "../Charts/VariableRadiusPie.vue";

export default {
  components: {
    VariableRadiusPie,
  },
  props: {
    api: {
      type: Object,
    },
  },
};
</script>
