<template>
  <div>
    <filter-section />

    <v-row>
      <v-col cols="6">
        <general-card class="d-flex flex-column align-center pa-5 mt-3">
          <v-icon class="big-icon mt-16 mb-16" color="#8cc73e"
            >mdi-lightbulb-on</v-icon
          >
          <h3 class="my-3 heading-title">Served Impressions</h3>
          <h1 class="font-weight-bold mb-15 audience-blue-text">
            3,005,535,083
          </h1>
        </general-card>
      </v-col>
      <v-col cols="6">
        <general-card class="mt-3" style="height: 97.5%">
          <br />
          <v-tabs
            right
            background-color="#ECF4F6"
            slider-color="#8cc73e"
            id="reach-tab"
          >
            <v-tab href="#age">
              <span class="custom-transform-class">Age Range</span>
            </v-tab>
            <v-tab href="#device">
              <span class="custom-transform-class">Devices</span>
            </v-tab>
            <v-tab href="#gender">
              <span class="custom-transform-class">Gender</span>
            </v-tab>
            <v-tab href="#network">
              <span class="custom-transform-class">Network</span>
            </v-tab>

            <v-tab-item value="age">
              <reach-tab :api="api">
                <v-list
                  v-for="([iconColor, stat, percentage, status], i) in ageReach"
                  :key="i"
                >
                  <v-list-item class="mb-1">
                    <v-icon small class="mr-2" :color="iconColor"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    <span class="text--disabled body-2">{{ stat }} yrs</span>
                    <span :class="percentageColor(status)"
                      >{{ percentage }}%
                      <v-icon x-small color="#70E09B" v-if="status === 'up'"
                        >mdi-arrow-up</v-icon
                      >
                      <v-icon x-small color="#E9559A" v-if="status === 'down'"
                        >mdi-arrow-down</v-icon
                      >
                    </span></v-list-item
                  >
                </v-list>
              </reach-tab>
            </v-tab-item>
            <v-tab-item value="device">
              <reach-tab :api="api">
                <v-list
                  v-for="([iconColor, stat, percentage, status],
                  i) in deviceReach"
                  :key="i"
                >
                  <v-list-item class="mb-1">
                    <v-icon small class="mr-2" :color="iconColor"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    <span class="text--disabled body-2">{{ stat }}</span>
                    <span :class="percentageColor(status)"
                      >{{ percentage }}%
                      <v-icon x-small color="#70E09B" v-if="status === 'up'"
                        >mdi-arrow-up</v-icon
                      >
                      <v-icon x-small color="#E9559A" v-if="status === 'down'"
                        >mdi-arrow-down</v-icon
                      >
                    </span></v-list-item
                  >
                </v-list>
              </reach-tab>
            </v-tab-item>
            <v-tab-item value="gender">
              <reach-tab :api="api">
                <v-list
                  v-for="([iconColor, stat, percentage, status],
                  i) in genderReach"
                  :key="i"
                >
                  <v-list-item class="mb-1">
                    <v-icon small class="mr-2" :color="iconColor"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    <span class="text--disabled body-2">{{ stat }}</span>
                    <span :class="percentageColor(status)"
                      >{{ percentage }}%
                      <v-icon x-small color="#70E09B" v-if="status === 'up'"
                        >mdi-arrow-up</v-icon
                      >
                      <v-icon x-small color="#E9559A" v-if="status === 'down'"
                        >mdi-arrow-down</v-icon
                      >
                    </span></v-list-item
                  >
                </v-list>
              </reach-tab>
            </v-tab-item>

            <v-tab-item value="network">
              <reach-tab :api="api">
                <v-list
                  v-for="([iconColor, stat, percentage, status],
                  i) in genderReach"
                  :key="i"
                >
                  <v-list-item class="mb-1">
                    <v-icon small class="mr-2" :color="iconColor"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    <span class="text--disabled body-2">{{ stat }}</span>
                    <span :class="percentageColor(status)"
                      >{{ percentage }}%
                      <v-icon x-small color="#70E09B" v-if="status === 'up'"
                        >mdi-arrow-up</v-icon
                      >
                      <v-icon x-small color="#E9559A" v-if="status === 'down'"
                        >mdi-arrow-down</v-icon
                      >
                    </span></v-list-item
                  >
                </v-list>
              </reach-tab>
            </v-tab-item>
          </v-tabs>
        </general-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <general-card class="pa-5">
          <h4 class="heading-title">Average CPL per industry</h4>
          <ul
            v-for="(industry, key) in api.industry_averages.CPL"
            :key="industry[key]"
          >
            <li class="text-capitalize mt-3 text-body-2 text--disabled">
              {{ Object.entries(industry)[0][0] }}
              <span class="float-right audience-blue-text font-weight-medium"
                >${{ Object.entries(industry)[0][1] }}</span
              >
            </li>
          </ul>
        </general-card>
      </v-col>
      <v-col>
        <general-card class="pa-5">
          <h4 class="heading-title">Average CTR per industry</h4>
          <ul
            v-for="(industry, key) in api.industry_averages.CTR"
            :key="industry[key]"
          >
            <li class="text-capitalize mt-3 text-body-2 text--disabled">
              {{ Object.entries(industry)[0][0] }}
              <span class="float-right audience-blue-text font-weight-medium"
                >{{ Object.entries(industry)[0][1].toFixed(1) }}%</span
              >
            </li>
          </ul>
        </general-card>
      </v-col>
      <v-col>
        <general-card class="pa-5">
          <h4 class="heading-title">Average CPM per industry</h4>
          <ul
            v-for="(industry, key) in api.industry_averages.CPM"
            :key="industry[key]"
          >
            <li class="text-capitalize mt-3 text-body-2 text--disabled">
              {{ Object.entries(industry)[0][0] }}
              <span class="float-right audience-blue-text font-weight-medium"
                >${{ Object.entries(industry)[0][1] }}</span
              >
            </li>
          </ul>
        </general-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <general-card class="pa-5">
          <h4 class="heading-title mb-6">Most popular sites</h4>
          <sites-apps-table
            :sitesAndApps="api.marketplace.sitesAndApps"
            :headers="headers"
          />
        </general-card>
      </v-col>
      <v-col>
        <general-card class="pa-5">
          <h4 class="heading-title mb-6">Most popular apps</h4>
          <sites-apps-table
            :sitesAndApps="api.marketplace.sitesAndApps"
            :headers="headers"
          />
        </general-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FilterSection from "../../components/Audiences/FilterSection.vue";
import GeneralCard from "../../components/Layouts/Cards/GeneralCard.vue";
import ReachTab from "../../components/Tabs/ReachTab.vue";
import SitesAppsTable from "../../components/Tables/SitesAppsTable.vue";

export default {
  data: () => ({
    ageReach: [
      ["#4F92ED", "16 - 25", 2.2, "up"],
      ["#3371E2", "26 - 35", 0.7, "down"],
      ["#70E09B", "36 - 45", 5.2, "up"],
      ["#F8C76C", "46 - 55", 0.2, "down"],
      ["#E9559A", "Over 55", 1.5, "down"],
    ],
    deviceReach: [
      ["#4F92ED", "Mobile Phone", 2.2, "up"],
      ["#3371E2", "Tablet", 0.7, "down"],
      ["#70E09B", "PC", 5.2, "up"],
      ["#F8C76C", "Smart TV", 0.2, "down"],
      ["#E9559A", "Smart Watch", 1.5, "down"],
    ],
    genderReach: [
      ["#4F92ED", "Male", 2.2, "up"],
      ["#3371E2", "Female", 0.7, "down"],
    ],
    headers: [
      {
        text: "",
        sortable: false,
        value: "details",
      },
    ],
  }),
  components: {
    FilterSection,
    GeneralCard,
    ReachTab,
    SitesAppsTable,
  },
  methods: {
    percentageColor(status) {
      if (status === "up") return "dashboard-green ml-2";
      if (status === "down") return "dashboard-red ml-2";
    },
  },
};
</script>

<style scoped>
.heading-color {
  color: #7c7c7c;
}

.heading-title {
  color: #817e7e;
}

.big-icon {
  font-size: 10rem !important;
}

.audience-blue-text {
  color: #3371e2;
}

ul > li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
}
</style>
